/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AdminImport } from './routes/admin'
import { Route as MainImport } from './routes/_main'
import { Route as AdminSimpleImport } from './routes/admin/_simple'
import { Route as MainServicesCreateImport } from './routes/_main/services/create'
import { Route as MainNetworkCreateIndexImport } from './routes/_main/network/create.index'

// Create Virtual Routes

const AboutLazyImport = createFileRoute('/about')()
const MainIndexLazyImport = createFileRoute('/_main/')()
const MainTestErrorLazyImport = createFileRoute('/_main/test-error')()
const AdminSimpleIndexLazyImport = createFileRoute('/admin/_simple/')()
const MainProfileIndexLazyImport = createFileRoute('/_main/profile/')()
const ConsoleLocationVmIdLazyImport = createFileRoute(
  '/console/$location/$vmId',
)()
const AdminSimpleVmimagesLazyImport = createFileRoute(
  '/admin/_simple/vm_images',
)()
const AdminSimpleVmconfLazyImport = createFileRoute('/admin/_simple/vm_conf')()
const AdminSimpleTrackingLazyImport = createFileRoute(
  '/admin/_simple/tracking',
)()
const AdminSimpleTestComponentsLazyImport = createFileRoute(
  '/admin/_simple/test-components',
)()
const AdminSimpleReferralLazyImport = createFileRoute(
  '/admin/_simple/referral',
)()
const AdminSimplePricesLazyImport = createFileRoute('/admin/_simple/prices')()
const AdminSimpleLocationsLazyImport = createFileRoute(
  '/admin/_simple/locations',
)()
const AdminSimpleHostpoolsLazyImport = createFileRoute(
  '/admin/_simple/host_pools',
)()
const AdminSimpleGeneralLazyImport = createFileRoute('/admin/_simple/general')()
const AdminSimpleCustomproductsLazyImport = createFileRoute(
  '/admin/_simple/custom_products',
)()
const AdminSimpleConfigurationLazyImport = createFileRoute(
  '/admin/_simple/configuration',
)()
const AdminSimpleCampaignsLazyImport = createFileRoute(
  '/admin/_simple/campaigns',
)()
const AdminSimpleAdminusersLazyImport = createFileRoute(
  '/admin/_simple/admin_users',
)()
const AdminDualVirtualmachinesLazyImport = createFileRoute(
  '/admin/_dual/virtual_machines',
)()
const AdminDualUsersLazyImport = createFileRoute('/admin/_dual/users')()
const AdminDualServicepackagesLazyImport = createFileRoute(
  '/admin/_dual/service_packages',
)()
const AdminDualReportsLazyImport = createFileRoute('/admin/_dual/reports')()
const AdminDualLoadbalancersLazyImport = createFileRoute(
  '/admin/_dual/load_balancers',
)()
const AdminDualHostsLazyImport = createFileRoute('/admin/_dual/hosts')()
const AdminDualFloatingipsLazyImport = createFileRoute(
  '/admin/_dual/floating_ips',
)()
const AdminDualBucketsLazyImport = createFileRoute('/admin/_dual/buckets')()
const AdminDualAccountsLazyImport = createFileRoute('/admin/_dual/accounts')()
const MainStorageCreateLazyImport = createFileRoute('/_main/storage/create')()
const MainSectionNameLazyImport = createFileRoute('/_main/section/$name')()
const MainMetalCreateLazyImport = createFileRoute('/_main/metal/create')()
const MainComputeCreateLazyImport = createFileRoute('/_main/compute/create')()
const MainBillingCreateLazyImport = createFileRoute('/_main/billing/create')()
const AdminConsoleLocationVmIdLazyImport = createFileRoute(
  '/admin/console/$location/$vmId',
)()
const AdminSimpleMetalSpecsLazyImport = createFileRoute(
  '/admin/_simple/metal/specs',
)()
const AdminSimpleMetalOsLazyImport = createFileRoute(
  '/admin/_simple/metal/os',
)()
const MainNetworkCreateTypeLazyImport = createFileRoute(
  '/_main/network/create/$type',
)()
const MainAccessCreateTypeLazyImport = createFileRoute(
  '/_main/access/create/$type',
)()
const AdminSimpleMetalMachinesIndexLazyImport = createFileRoute(
  '/admin/_simple/metal/machines/',
)()
const MainStorageBucketStorageNameIndexLazyImport = createFileRoute(
  '/_main/storage/bucket/$storageName/',
)()
const MainMetalLeaseUuidIndexLazyImport = createFileRoute(
  '/_main/metal/lease/$uuid/',
)()
const MainBillingAccountIdIndexLazyImport = createFileRoute(
  '/_main/billing/account/$id/',
)()
const MainAccessTokenIdIndexLazyImport = createFileRoute(
  '/_main/access/token/$id/',
)()
const MainAccessDelegationIdIndexLazyImport = createFileRoute(
  '/_main/access/delegation/$id/',
)()
const MainBillingAccountIdEditLazyImport = createFileRoute(
  '/_main/billing/account/$id/edit',
)()
const MainServicesServiceLocationServiceIdIndexLazyImport = createFileRoute(
  '/_main/services/service/$location/$serviceId/',
)()
const MainNetworkVpcLocationUuidIndexLazyImport = createFileRoute(
  '/_main/network/vpc/$location/$uuid/',
)()
const MainNetworkLoadbalancerLocationUuidIndexLazyImport = createFileRoute(
  '/_main/network/load_balancer/$location/$uuid/',
)()
const MainNetworkIpaddressLocationUuidIndexLazyImport = createFileRoute(
  '/_main/network/ip_address/$location/$uuid/',
)()
const MainComputeVmLocationVmIdIndexLazyImport = createFileRoute(
  '/_main/compute/vm/$location/$vmId/',
)()
const AdminSimpleMetalMachinesHistoryLocationUuidLazyImport = createFileRoute(
  '/admin/_simple/metal/machines/history/$location/$uuid',
)()
const MainBillingAccountIdDetailTypeInvoiceIdLazyImport = createFileRoute(
  '/_main/billing/account/$id/detail/$type/$invoiceId',
)()

// Create/Update Routes

const AboutLazyRoute = AboutLazyImport.update({
  id: '/about',
  path: '/about',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/about.lazy').then((d) => d.Route))

const AdminRoute = AdminImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => rootRoute,
} as any)

const MainRoute = MainImport.update({
  id: '/_main',
  getParentRoute: () => rootRoute,
} as any)

const MainIndexLazyRoute = MainIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => MainRoute,
} as any).lazy(() => import('./routes/_main/index.lazy').then((d) => d.Route))

const MainTestErrorLazyRoute = MainTestErrorLazyImport.update({
  id: '/test-error',
  path: '/test-error',
  getParentRoute: () => MainRoute,
} as any).lazy(() =>
  import('./routes/_main/test-error.lazy').then((d) => d.Route),
)

const AdminSimpleRoute = AdminSimpleImport.update({
  id: '/_simple',
  getParentRoute: () => AdminRoute,
} as any)

const AdminSimpleIndexLazyRoute = AdminSimpleIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AdminSimpleRoute,
} as any).lazy(() =>
  import('./routes/admin/_simple/index.lazy').then((d) => d.Route),
)

const MainProfileIndexLazyRoute = MainProfileIndexLazyImport.update({
  id: '/profile/',
  path: '/profile/',
  getParentRoute: () => MainRoute,
} as any).lazy(() =>
  import('./routes/_main/profile/index.lazy').then((d) => d.Route),
)

const ConsoleLocationVmIdLazyRoute = ConsoleLocationVmIdLazyImport.update({
  id: '/console/$location/$vmId',
  path: '/console/$location/$vmId',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/console.$location.$vmId.lazy').then((d) => d.Route),
)

const AdminSimpleVmimagesLazyRoute = AdminSimpleVmimagesLazyImport.update({
  id: '/vm_images',
  path: '/vm_images',
  getParentRoute: () => AdminSimpleRoute,
} as any).lazy(() =>
  import('./routes/admin/_simple/vm_images.lazy').then((d) => d.Route),
)

const AdminSimpleVmconfLazyRoute = AdminSimpleVmconfLazyImport.update({
  id: '/vm_conf',
  path: '/vm_conf',
  getParentRoute: () => AdminSimpleRoute,
} as any).lazy(() =>
  import('./routes/admin/_simple/vm_conf.lazy').then((d) => d.Route),
)

const AdminSimpleTrackingLazyRoute = AdminSimpleTrackingLazyImport.update({
  id: '/tracking',
  path: '/tracking',
  getParentRoute: () => AdminSimpleRoute,
} as any).lazy(() =>
  import('./routes/admin/_simple/tracking.lazy').then((d) => d.Route),
)

const AdminSimpleTestComponentsLazyRoute =
  AdminSimpleTestComponentsLazyImport.update({
    id: '/test-components',
    path: '/test-components',
    getParentRoute: () => AdminSimpleRoute,
  } as any).lazy(() =>
    import('./routes/admin/_simple/test-components.lazy').then((d) => d.Route),
  )

const AdminSimpleReferralLazyRoute = AdminSimpleReferralLazyImport.update({
  id: '/referral',
  path: '/referral',
  getParentRoute: () => AdminSimpleRoute,
} as any).lazy(() =>
  import('./routes/admin/_simple/referral.lazy').then((d) => d.Route),
)

const AdminSimplePricesLazyRoute = AdminSimplePricesLazyImport.update({
  id: '/prices',
  path: '/prices',
  getParentRoute: () => AdminSimpleRoute,
} as any).lazy(() =>
  import('./routes/admin/_simple/prices.lazy').then((d) => d.Route),
)

const AdminSimpleLocationsLazyRoute = AdminSimpleLocationsLazyImport.update({
  id: '/locations',
  path: '/locations',
  getParentRoute: () => AdminSimpleRoute,
} as any).lazy(() =>
  import('./routes/admin/_simple/locations.lazy').then((d) => d.Route),
)

const AdminSimpleHostpoolsLazyRoute = AdminSimpleHostpoolsLazyImport.update({
  id: '/host_pools',
  path: '/host_pools',
  getParentRoute: () => AdminSimpleRoute,
} as any).lazy(() =>
  import('./routes/admin/_simple/host_pools.lazy').then((d) => d.Route),
)

const AdminSimpleGeneralLazyRoute = AdminSimpleGeneralLazyImport.update({
  id: '/general',
  path: '/general',
  getParentRoute: () => AdminSimpleRoute,
} as any).lazy(() =>
  import('./routes/admin/_simple/general.lazy').then((d) => d.Route),
)

const AdminSimpleCustomproductsLazyRoute =
  AdminSimpleCustomproductsLazyImport.update({
    id: '/custom_products',
    path: '/custom_products',
    getParentRoute: () => AdminSimpleRoute,
  } as any).lazy(() =>
    import('./routes/admin/_simple/custom_products.lazy').then((d) => d.Route),
  )

const AdminSimpleConfigurationLazyRoute =
  AdminSimpleConfigurationLazyImport.update({
    id: '/configuration',
    path: '/configuration',
    getParentRoute: () => AdminSimpleRoute,
  } as any).lazy(() =>
    import('./routes/admin/_simple/configuration.lazy').then((d) => d.Route),
  )

const AdminSimpleCampaignsLazyRoute = AdminSimpleCampaignsLazyImport.update({
  id: '/campaigns',
  path: '/campaigns',
  getParentRoute: () => AdminSimpleRoute,
} as any).lazy(() =>
  import('./routes/admin/_simple/campaigns.lazy').then((d) => d.Route),
)

const AdminSimpleAdminusersLazyRoute = AdminSimpleAdminusersLazyImport.update({
  id: '/admin_users',
  path: '/admin_users',
  getParentRoute: () => AdminSimpleRoute,
} as any).lazy(() =>
  import('./routes/admin/_simple/admin_users.lazy').then((d) => d.Route),
)

const AdminDualVirtualmachinesLazyRoute =
  AdminDualVirtualmachinesLazyImport.update({
    id: '/_dual/virtual_machines',
    path: '/virtual_machines',
    getParentRoute: () => AdminRoute,
  } as any).lazy(() =>
    import('./routes/admin/_dual/virtual_machines.lazy').then((d) => d.Route),
  )

const AdminDualUsersLazyRoute = AdminDualUsersLazyImport.update({
  id: '/_dual/users',
  path: '/users',
  getParentRoute: () => AdminRoute,
} as any).lazy(() =>
  import('./routes/admin/_dual/users.lazy').then((d) => d.Route),
)

const AdminDualServicepackagesLazyRoute =
  AdminDualServicepackagesLazyImport.update({
    id: '/_dual/service_packages',
    path: '/service_packages',
    getParentRoute: () => AdminRoute,
  } as any).lazy(() =>
    import('./routes/admin/_dual/service_packages.lazy').then((d) => d.Route),
  )

const AdminDualReportsLazyRoute = AdminDualReportsLazyImport.update({
  id: '/_dual/reports',
  path: '/reports',
  getParentRoute: () => AdminRoute,
} as any).lazy(() =>
  import('./routes/admin/_dual/reports.lazy').then((d) => d.Route),
)

const AdminDualLoadbalancersLazyRoute = AdminDualLoadbalancersLazyImport.update(
  {
    id: '/_dual/load_balancers',
    path: '/load_balancers',
    getParentRoute: () => AdminRoute,
  } as any,
).lazy(() =>
  import('./routes/admin/_dual/load_balancers.lazy').then((d) => d.Route),
)

const AdminDualHostsLazyRoute = AdminDualHostsLazyImport.update({
  id: '/_dual/hosts',
  path: '/hosts',
  getParentRoute: () => AdminRoute,
} as any).lazy(() =>
  import('./routes/admin/_dual/hosts.lazy').then((d) => d.Route),
)

const AdminDualFloatingipsLazyRoute = AdminDualFloatingipsLazyImport.update({
  id: '/_dual/floating_ips',
  path: '/floating_ips',
  getParentRoute: () => AdminRoute,
} as any).lazy(() =>
  import('./routes/admin/_dual/floating_ips.lazy').then((d) => d.Route),
)

const AdminDualBucketsLazyRoute = AdminDualBucketsLazyImport.update({
  id: '/_dual/buckets',
  path: '/buckets',
  getParentRoute: () => AdminRoute,
} as any).lazy(() =>
  import('./routes/admin/_dual/buckets.lazy').then((d) => d.Route),
)

const AdminDualAccountsLazyRoute = AdminDualAccountsLazyImport.update({
  id: '/_dual/accounts',
  path: '/accounts',
  getParentRoute: () => AdminRoute,
} as any).lazy(() =>
  import('./routes/admin/_dual/accounts.lazy').then((d) => d.Route),
)

const MainStorageCreateLazyRoute = MainStorageCreateLazyImport.update({
  id: '/storage/create',
  path: '/storage/create',
  getParentRoute: () => MainRoute,
} as any).lazy(() =>
  import('./routes/_main/storage/create.lazy').then((d) => d.Route),
)

const MainSectionNameLazyRoute = MainSectionNameLazyImport.update({
  id: '/section/$name',
  path: '/section/$name',
  getParentRoute: () => MainRoute,
} as any).lazy(() =>
  import('./routes/_main/section/$name.lazy').then((d) => d.Route),
)

const MainMetalCreateLazyRoute = MainMetalCreateLazyImport.update({
  id: '/metal/create',
  path: '/metal/create',
  getParentRoute: () => MainRoute,
} as any).lazy(() =>
  import('./routes/_main/metal/create.lazy').then((d) => d.Route),
)

const MainComputeCreateLazyRoute = MainComputeCreateLazyImport.update({
  id: '/compute/create',
  path: '/compute/create',
  getParentRoute: () => MainRoute,
} as any).lazy(() =>
  import('./routes/_main/compute/create.lazy').then((d) => d.Route),
)

const MainBillingCreateLazyRoute = MainBillingCreateLazyImport.update({
  id: '/billing/create',
  path: '/billing/create',
  getParentRoute: () => MainRoute,
} as any).lazy(() =>
  import('./routes/_main/billing/create.lazy').then((d) => d.Route),
)

const MainServicesCreateRoute = MainServicesCreateImport.update({
  id: '/services/create',
  path: '/services/create',
  getParentRoute: () => MainRoute,
} as any).lazy(() =>
  import('./routes/_main/services/create.lazy').then((d) => d.Route),
)

const MainNetworkCreateIndexRoute = MainNetworkCreateIndexImport.update({
  id: '/network/create/',
  path: '/network/create/',
  getParentRoute: () => MainRoute,
} as any)

const AdminConsoleLocationVmIdLazyRoute =
  AdminConsoleLocationVmIdLazyImport.update({
    id: '/console/$location/$vmId',
    path: '/console/$location/$vmId',
    getParentRoute: () => AdminRoute,
  } as any).lazy(() =>
    import('./routes/admin/console.$location.$vmId.lazy').then((d) => d.Route),
  )

const AdminSimpleMetalSpecsLazyRoute = AdminSimpleMetalSpecsLazyImport.update({
  id: '/metal/specs',
  path: '/metal/specs',
  getParentRoute: () => AdminSimpleRoute,
} as any).lazy(() =>
  import('./routes/admin/_simple/metal/specs.lazy').then((d) => d.Route),
)

const AdminSimpleMetalOsLazyRoute = AdminSimpleMetalOsLazyImport.update({
  id: '/metal/os',
  path: '/metal/os',
  getParentRoute: () => AdminSimpleRoute,
} as any).lazy(() =>
  import('./routes/admin/_simple/metal/os.lazy').then((d) => d.Route),
)

const MainNetworkCreateTypeLazyRoute = MainNetworkCreateTypeLazyImport.update({
  id: '/network/create/$type',
  path: '/network/create/$type',
  getParentRoute: () => MainRoute,
} as any).lazy(() =>
  import('./routes/_main/network/create.$type.lazy').then((d) => d.Route),
)

const MainAccessCreateTypeLazyRoute = MainAccessCreateTypeLazyImport.update({
  id: '/access/create/$type',
  path: '/access/create/$type',
  getParentRoute: () => MainRoute,
} as any).lazy(() =>
  import('./routes/_main/access/create.$type.lazy').then((d) => d.Route),
)

const AdminSimpleMetalMachinesIndexLazyRoute =
  AdminSimpleMetalMachinesIndexLazyImport.update({
    id: '/metal/machines/',
    path: '/metal/machines/',
    getParentRoute: () => AdminSimpleRoute,
  } as any).lazy(() =>
    import('./routes/admin/_simple/metal/machines/index.lazy').then(
      (d) => d.Route,
    ),
  )

const MainStorageBucketStorageNameIndexLazyRoute =
  MainStorageBucketStorageNameIndexLazyImport.update({
    id: '/storage/bucket/$storageName/',
    path: '/storage/bucket/$storageName/',
    getParentRoute: () => MainRoute,
  } as any).lazy(() =>
    import('./routes/_main/storage/bucket.$storageName/index.lazy').then(
      (d) => d.Route,
    ),
  )

const MainMetalLeaseUuidIndexLazyRoute =
  MainMetalLeaseUuidIndexLazyImport.update({
    id: '/metal/lease/$uuid/',
    path: '/metal/lease/$uuid/',
    getParentRoute: () => MainRoute,
  } as any).lazy(() =>
    import('./routes/_main/metal/lease.$uuid/index.lazy').then((d) => d.Route),
  )

const MainBillingAccountIdIndexLazyRoute =
  MainBillingAccountIdIndexLazyImport.update({
    id: '/billing/account/$id/',
    path: '/billing/account/$id/',
    getParentRoute: () => MainRoute,
  } as any).lazy(() =>
    import('./routes/_main/billing/account.$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const MainAccessTokenIdIndexLazyRoute = MainAccessTokenIdIndexLazyImport.update(
  {
    id: '/access/token/$id/',
    path: '/access/token/$id/',
    getParentRoute: () => MainRoute,
  } as any,
).lazy(() =>
  import('./routes/_main/access/token/$id/index.lazy').then((d) => d.Route),
)

const MainAccessDelegationIdIndexLazyRoute =
  MainAccessDelegationIdIndexLazyImport.update({
    id: '/access/delegation/$id/',
    path: '/access/delegation/$id/',
    getParentRoute: () => MainRoute,
  } as any).lazy(() =>
    import('./routes/_main/access/delegation/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const MainBillingAccountIdEditLazyRoute =
  MainBillingAccountIdEditLazyImport.update({
    id: '/billing/account/$id/edit',
    path: '/billing/account/$id/edit',
    getParentRoute: () => MainRoute,
  } as any).lazy(() =>
    import('./routes/_main/billing/account.$id/edit.lazy').then((d) => d.Route),
  )

const MainServicesServiceLocationServiceIdIndexLazyRoute =
  MainServicesServiceLocationServiceIdIndexLazyImport.update({
    id: '/services/service/$location/$serviceId/',
    path: '/services/service/$location/$serviceId/',
    getParentRoute: () => MainRoute,
  } as any).lazy(() =>
    import(
      './routes/_main/services/service.$location.$serviceId/index.lazy'
    ).then((d) => d.Route),
  )

const MainNetworkVpcLocationUuidIndexLazyRoute =
  MainNetworkVpcLocationUuidIndexLazyImport.update({
    id: '/network/vpc/$location/$uuid/',
    path: '/network/vpc/$location/$uuid/',
    getParentRoute: () => MainRoute,
  } as any).lazy(() =>
    import('./routes/_main/network/vpc/$location/$uuid/index.lazy').then(
      (d) => d.Route,
    ),
  )

const MainNetworkLoadbalancerLocationUuidIndexLazyRoute =
  MainNetworkLoadbalancerLocationUuidIndexLazyImport.update({
    id: '/network/load_balancer/$location/$uuid/',
    path: '/network/load_balancer/$location/$uuid/',
    getParentRoute: () => MainRoute,
  } as any).lazy(() =>
    import(
      './routes/_main/network/load_balancer/$location/$uuid/index.lazy'
    ).then((d) => d.Route),
  )

const MainNetworkIpaddressLocationUuidIndexLazyRoute =
  MainNetworkIpaddressLocationUuidIndexLazyImport.update({
    id: '/network/ip_address/$location/$uuid/',
    path: '/network/ip_address/$location/$uuid/',
    getParentRoute: () => MainRoute,
  } as any).lazy(() =>
    import('./routes/_main/network/ip_address/$location/$uuid/index.lazy').then(
      (d) => d.Route,
    ),
  )

const MainComputeVmLocationVmIdIndexLazyRoute =
  MainComputeVmLocationVmIdIndexLazyImport.update({
    id: '/compute/vm/$location/$vmId/',
    path: '/compute/vm/$location/$vmId/',
    getParentRoute: () => MainRoute,
  } as any).lazy(() =>
    import('./routes/_main/compute/vm.$location.$vmId/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AdminSimpleMetalMachinesHistoryLocationUuidLazyRoute =
  AdminSimpleMetalMachinesHistoryLocationUuidLazyImport.update({
    id: '/metal/machines/history/$location/$uuid',
    path: '/metal/machines/history/$location/$uuid',
    getParentRoute: () => AdminSimpleRoute,
  } as any).lazy(() =>
    import(
      './routes/admin/_simple/metal/machines/history.$location.$uuid.lazy'
    ).then((d) => d.Route),
  )

const MainBillingAccountIdDetailTypeInvoiceIdLazyRoute =
  MainBillingAccountIdDetailTypeInvoiceIdLazyImport.update({
    id: '/billing/account/$id/detail/$type/$invoiceId',
    path: '/billing/account/$id/detail/$type/$invoiceId',
    getParentRoute: () => MainRoute,
  } as any).lazy(() =>
    import(
      './routes/_main/billing/account.$id/detail.$type.$invoiceId.lazy'
    ).then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_main': {
      id: '/_main'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof MainImport
      parentRoute: typeof rootRoute
    }
    '/admin': {
      id: '/admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof AdminImport
      parentRoute: typeof rootRoute
    }
    '/about': {
      id: '/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof AboutLazyImport
      parentRoute: typeof rootRoute
    }
    '/admin/_simple': {
      id: '/admin/_simple'
      path: ''
      fullPath: '/admin'
      preLoaderRoute: typeof AdminSimpleImport
      parentRoute: typeof AdminImport
    }
    '/_main/test-error': {
      id: '/_main/test-error'
      path: '/test-error'
      fullPath: '/test-error'
      preLoaderRoute: typeof MainTestErrorLazyImport
      parentRoute: typeof MainImport
    }
    '/_main/': {
      id: '/_main/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof MainIndexLazyImport
      parentRoute: typeof MainImport
    }
    '/_main/services/create': {
      id: '/_main/services/create'
      path: '/services/create'
      fullPath: '/services/create'
      preLoaderRoute: typeof MainServicesCreateImport
      parentRoute: typeof MainImport
    }
    '/_main/billing/create': {
      id: '/_main/billing/create'
      path: '/billing/create'
      fullPath: '/billing/create'
      preLoaderRoute: typeof MainBillingCreateLazyImport
      parentRoute: typeof MainImport
    }
    '/_main/compute/create': {
      id: '/_main/compute/create'
      path: '/compute/create'
      fullPath: '/compute/create'
      preLoaderRoute: typeof MainComputeCreateLazyImport
      parentRoute: typeof MainImport
    }
    '/_main/metal/create': {
      id: '/_main/metal/create'
      path: '/metal/create'
      fullPath: '/metal/create'
      preLoaderRoute: typeof MainMetalCreateLazyImport
      parentRoute: typeof MainImport
    }
    '/_main/section/$name': {
      id: '/_main/section/$name'
      path: '/section/$name'
      fullPath: '/section/$name'
      preLoaderRoute: typeof MainSectionNameLazyImport
      parentRoute: typeof MainImport
    }
    '/_main/storage/create': {
      id: '/_main/storage/create'
      path: '/storage/create'
      fullPath: '/storage/create'
      preLoaderRoute: typeof MainStorageCreateLazyImport
      parentRoute: typeof MainImport
    }
    '/admin/_dual/accounts': {
      id: '/admin/_dual/accounts'
      path: '/accounts'
      fullPath: '/admin/accounts'
      preLoaderRoute: typeof AdminDualAccountsLazyImport
      parentRoute: typeof AdminImport
    }
    '/admin/_dual/buckets': {
      id: '/admin/_dual/buckets'
      path: '/buckets'
      fullPath: '/admin/buckets'
      preLoaderRoute: typeof AdminDualBucketsLazyImport
      parentRoute: typeof AdminImport
    }
    '/admin/_dual/floating_ips': {
      id: '/admin/_dual/floating_ips'
      path: '/floating_ips'
      fullPath: '/admin/floating_ips'
      preLoaderRoute: typeof AdminDualFloatingipsLazyImport
      parentRoute: typeof AdminImport
    }
    '/admin/_dual/hosts': {
      id: '/admin/_dual/hosts'
      path: '/hosts'
      fullPath: '/admin/hosts'
      preLoaderRoute: typeof AdminDualHostsLazyImport
      parentRoute: typeof AdminImport
    }
    '/admin/_dual/load_balancers': {
      id: '/admin/_dual/load_balancers'
      path: '/load_balancers'
      fullPath: '/admin/load_balancers'
      preLoaderRoute: typeof AdminDualLoadbalancersLazyImport
      parentRoute: typeof AdminImport
    }
    '/admin/_dual/reports': {
      id: '/admin/_dual/reports'
      path: '/reports'
      fullPath: '/admin/reports'
      preLoaderRoute: typeof AdminDualReportsLazyImport
      parentRoute: typeof AdminImport
    }
    '/admin/_dual/service_packages': {
      id: '/admin/_dual/service_packages'
      path: '/service_packages'
      fullPath: '/admin/service_packages'
      preLoaderRoute: typeof AdminDualServicepackagesLazyImport
      parentRoute: typeof AdminImport
    }
    '/admin/_dual/users': {
      id: '/admin/_dual/users'
      path: '/users'
      fullPath: '/admin/users'
      preLoaderRoute: typeof AdminDualUsersLazyImport
      parentRoute: typeof AdminImport
    }
    '/admin/_dual/virtual_machines': {
      id: '/admin/_dual/virtual_machines'
      path: '/virtual_machines'
      fullPath: '/admin/virtual_machines'
      preLoaderRoute: typeof AdminDualVirtualmachinesLazyImport
      parentRoute: typeof AdminImport
    }
    '/admin/_simple/admin_users': {
      id: '/admin/_simple/admin_users'
      path: '/admin_users'
      fullPath: '/admin/admin_users'
      preLoaderRoute: typeof AdminSimpleAdminusersLazyImport
      parentRoute: typeof AdminSimpleImport
    }
    '/admin/_simple/campaigns': {
      id: '/admin/_simple/campaigns'
      path: '/campaigns'
      fullPath: '/admin/campaigns'
      preLoaderRoute: typeof AdminSimpleCampaignsLazyImport
      parentRoute: typeof AdminSimpleImport
    }
    '/admin/_simple/configuration': {
      id: '/admin/_simple/configuration'
      path: '/configuration'
      fullPath: '/admin/configuration'
      preLoaderRoute: typeof AdminSimpleConfigurationLazyImport
      parentRoute: typeof AdminSimpleImport
    }
    '/admin/_simple/custom_products': {
      id: '/admin/_simple/custom_products'
      path: '/custom_products'
      fullPath: '/admin/custom_products'
      preLoaderRoute: typeof AdminSimpleCustomproductsLazyImport
      parentRoute: typeof AdminSimpleImport
    }
    '/admin/_simple/general': {
      id: '/admin/_simple/general'
      path: '/general'
      fullPath: '/admin/general'
      preLoaderRoute: typeof AdminSimpleGeneralLazyImport
      parentRoute: typeof AdminSimpleImport
    }
    '/admin/_simple/host_pools': {
      id: '/admin/_simple/host_pools'
      path: '/host_pools'
      fullPath: '/admin/host_pools'
      preLoaderRoute: typeof AdminSimpleHostpoolsLazyImport
      parentRoute: typeof AdminSimpleImport
    }
    '/admin/_simple/locations': {
      id: '/admin/_simple/locations'
      path: '/locations'
      fullPath: '/admin/locations'
      preLoaderRoute: typeof AdminSimpleLocationsLazyImport
      parentRoute: typeof AdminSimpleImport
    }
    '/admin/_simple/prices': {
      id: '/admin/_simple/prices'
      path: '/prices'
      fullPath: '/admin/prices'
      preLoaderRoute: typeof AdminSimplePricesLazyImport
      parentRoute: typeof AdminSimpleImport
    }
    '/admin/_simple/referral': {
      id: '/admin/_simple/referral'
      path: '/referral'
      fullPath: '/admin/referral'
      preLoaderRoute: typeof AdminSimpleReferralLazyImport
      parentRoute: typeof AdminSimpleImport
    }
    '/admin/_simple/test-components': {
      id: '/admin/_simple/test-components'
      path: '/test-components'
      fullPath: '/admin/test-components'
      preLoaderRoute: typeof AdminSimpleTestComponentsLazyImport
      parentRoute: typeof AdminSimpleImport
    }
    '/admin/_simple/tracking': {
      id: '/admin/_simple/tracking'
      path: '/tracking'
      fullPath: '/admin/tracking'
      preLoaderRoute: typeof AdminSimpleTrackingLazyImport
      parentRoute: typeof AdminSimpleImport
    }
    '/admin/_simple/vm_conf': {
      id: '/admin/_simple/vm_conf'
      path: '/vm_conf'
      fullPath: '/admin/vm_conf'
      preLoaderRoute: typeof AdminSimpleVmconfLazyImport
      parentRoute: typeof AdminSimpleImport
    }
    '/admin/_simple/vm_images': {
      id: '/admin/_simple/vm_images'
      path: '/vm_images'
      fullPath: '/admin/vm_images'
      preLoaderRoute: typeof AdminSimpleVmimagesLazyImport
      parentRoute: typeof AdminSimpleImport
    }
    '/console/$location/$vmId': {
      id: '/console/$location/$vmId'
      path: '/console/$location/$vmId'
      fullPath: '/console/$location/$vmId'
      preLoaderRoute: typeof ConsoleLocationVmIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/_main/profile/': {
      id: '/_main/profile/'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof MainProfileIndexLazyImport
      parentRoute: typeof MainImport
    }
    '/admin/_simple/': {
      id: '/admin/_simple/'
      path: '/'
      fullPath: '/admin/'
      preLoaderRoute: typeof AdminSimpleIndexLazyImport
      parentRoute: typeof AdminSimpleImport
    }
    '/_main/access/create/$type': {
      id: '/_main/access/create/$type'
      path: '/access/create/$type'
      fullPath: '/access/create/$type'
      preLoaderRoute: typeof MainAccessCreateTypeLazyImport
      parentRoute: typeof MainImport
    }
    '/_main/network/create/$type': {
      id: '/_main/network/create/$type'
      path: '/network/create/$type'
      fullPath: '/network/create/$type'
      preLoaderRoute: typeof MainNetworkCreateTypeLazyImport
      parentRoute: typeof MainImport
    }
    '/admin/_simple/metal/os': {
      id: '/admin/_simple/metal/os'
      path: '/metal/os'
      fullPath: '/admin/metal/os'
      preLoaderRoute: typeof AdminSimpleMetalOsLazyImport
      parentRoute: typeof AdminSimpleImport
    }
    '/admin/_simple/metal/specs': {
      id: '/admin/_simple/metal/specs'
      path: '/metal/specs'
      fullPath: '/admin/metal/specs'
      preLoaderRoute: typeof AdminSimpleMetalSpecsLazyImport
      parentRoute: typeof AdminSimpleImport
    }
    '/admin/console/$location/$vmId': {
      id: '/admin/console/$location/$vmId'
      path: '/console/$location/$vmId'
      fullPath: '/admin/console/$location/$vmId'
      preLoaderRoute: typeof AdminConsoleLocationVmIdLazyImport
      parentRoute: typeof AdminImport
    }
    '/_main/network/create/': {
      id: '/_main/network/create/'
      path: '/network/create'
      fullPath: '/network/create'
      preLoaderRoute: typeof MainNetworkCreateIndexImport
      parentRoute: typeof MainImport
    }
    '/_main/billing/account/$id/edit': {
      id: '/_main/billing/account/$id/edit'
      path: '/billing/account/$id/edit'
      fullPath: '/billing/account/$id/edit'
      preLoaderRoute: typeof MainBillingAccountIdEditLazyImport
      parentRoute: typeof MainImport
    }
    '/_main/access/delegation/$id/': {
      id: '/_main/access/delegation/$id/'
      path: '/access/delegation/$id'
      fullPath: '/access/delegation/$id'
      preLoaderRoute: typeof MainAccessDelegationIdIndexLazyImport
      parentRoute: typeof MainImport
    }
    '/_main/access/token/$id/': {
      id: '/_main/access/token/$id/'
      path: '/access/token/$id'
      fullPath: '/access/token/$id'
      preLoaderRoute: typeof MainAccessTokenIdIndexLazyImport
      parentRoute: typeof MainImport
    }
    '/_main/billing/account/$id/': {
      id: '/_main/billing/account/$id/'
      path: '/billing/account/$id'
      fullPath: '/billing/account/$id'
      preLoaderRoute: typeof MainBillingAccountIdIndexLazyImport
      parentRoute: typeof MainImport
    }
    '/_main/metal/lease/$uuid/': {
      id: '/_main/metal/lease/$uuid/'
      path: '/metal/lease/$uuid'
      fullPath: '/metal/lease/$uuid'
      preLoaderRoute: typeof MainMetalLeaseUuidIndexLazyImport
      parentRoute: typeof MainImport
    }
    '/_main/storage/bucket/$storageName/': {
      id: '/_main/storage/bucket/$storageName/'
      path: '/storage/bucket/$storageName'
      fullPath: '/storage/bucket/$storageName'
      preLoaderRoute: typeof MainStorageBucketStorageNameIndexLazyImport
      parentRoute: typeof MainImport
    }
    '/admin/_simple/metal/machines/': {
      id: '/admin/_simple/metal/machines/'
      path: '/metal/machines'
      fullPath: '/admin/metal/machines'
      preLoaderRoute: typeof AdminSimpleMetalMachinesIndexLazyImport
      parentRoute: typeof AdminSimpleImport
    }
    '/_main/compute/vm/$location/$vmId/': {
      id: '/_main/compute/vm/$location/$vmId/'
      path: '/compute/vm/$location/$vmId'
      fullPath: '/compute/vm/$location/$vmId'
      preLoaderRoute: typeof MainComputeVmLocationVmIdIndexLazyImport
      parentRoute: typeof MainImport
    }
    '/_main/network/ip_address/$location/$uuid/': {
      id: '/_main/network/ip_address/$location/$uuid/'
      path: '/network/ip_address/$location/$uuid'
      fullPath: '/network/ip_address/$location/$uuid'
      preLoaderRoute: typeof MainNetworkIpaddressLocationUuidIndexLazyImport
      parentRoute: typeof MainImport
    }
    '/_main/network/load_balancer/$location/$uuid/': {
      id: '/_main/network/load_balancer/$location/$uuid/'
      path: '/network/load_balancer/$location/$uuid'
      fullPath: '/network/load_balancer/$location/$uuid'
      preLoaderRoute: typeof MainNetworkLoadbalancerLocationUuidIndexLazyImport
      parentRoute: typeof MainImport
    }
    '/_main/network/vpc/$location/$uuid/': {
      id: '/_main/network/vpc/$location/$uuid/'
      path: '/network/vpc/$location/$uuid'
      fullPath: '/network/vpc/$location/$uuid'
      preLoaderRoute: typeof MainNetworkVpcLocationUuidIndexLazyImport
      parentRoute: typeof MainImport
    }
    '/_main/services/service/$location/$serviceId/': {
      id: '/_main/services/service/$location/$serviceId/'
      path: '/services/service/$location/$serviceId'
      fullPath: '/services/service/$location/$serviceId'
      preLoaderRoute: typeof MainServicesServiceLocationServiceIdIndexLazyImport
      parentRoute: typeof MainImport
    }
    '/_main/billing/account/$id/detail/$type/$invoiceId': {
      id: '/_main/billing/account/$id/detail/$type/$invoiceId'
      path: '/billing/account/$id/detail/$type/$invoiceId'
      fullPath: '/billing/account/$id/detail/$type/$invoiceId'
      preLoaderRoute: typeof MainBillingAccountIdDetailTypeInvoiceIdLazyImport
      parentRoute: typeof MainImport
    }
    '/admin/_simple/metal/machines/history/$location/$uuid': {
      id: '/admin/_simple/metal/machines/history/$location/$uuid'
      path: '/metal/machines/history/$location/$uuid'
      fullPath: '/admin/metal/machines/history/$location/$uuid'
      preLoaderRoute: typeof AdminSimpleMetalMachinesHistoryLocationUuidLazyImport
      parentRoute: typeof AdminSimpleImport
    }
  }
}

// Create and export the route tree

interface MainRouteChildren {
  MainTestErrorLazyRoute: typeof MainTestErrorLazyRoute
  MainIndexLazyRoute: typeof MainIndexLazyRoute
  MainServicesCreateRoute: typeof MainServicesCreateRoute
  MainBillingCreateLazyRoute: typeof MainBillingCreateLazyRoute
  MainComputeCreateLazyRoute: typeof MainComputeCreateLazyRoute
  MainMetalCreateLazyRoute: typeof MainMetalCreateLazyRoute
  MainSectionNameLazyRoute: typeof MainSectionNameLazyRoute
  MainStorageCreateLazyRoute: typeof MainStorageCreateLazyRoute
  MainProfileIndexLazyRoute: typeof MainProfileIndexLazyRoute
  MainAccessCreateTypeLazyRoute: typeof MainAccessCreateTypeLazyRoute
  MainNetworkCreateTypeLazyRoute: typeof MainNetworkCreateTypeLazyRoute
  MainNetworkCreateIndexRoute: typeof MainNetworkCreateIndexRoute
  MainBillingAccountIdEditLazyRoute: typeof MainBillingAccountIdEditLazyRoute
  MainAccessDelegationIdIndexLazyRoute: typeof MainAccessDelegationIdIndexLazyRoute
  MainAccessTokenIdIndexLazyRoute: typeof MainAccessTokenIdIndexLazyRoute
  MainBillingAccountIdIndexLazyRoute: typeof MainBillingAccountIdIndexLazyRoute
  MainMetalLeaseUuidIndexLazyRoute: typeof MainMetalLeaseUuidIndexLazyRoute
  MainStorageBucketStorageNameIndexLazyRoute: typeof MainStorageBucketStorageNameIndexLazyRoute
  MainComputeVmLocationVmIdIndexLazyRoute: typeof MainComputeVmLocationVmIdIndexLazyRoute
  MainNetworkIpaddressLocationUuidIndexLazyRoute: typeof MainNetworkIpaddressLocationUuidIndexLazyRoute
  MainNetworkLoadbalancerLocationUuidIndexLazyRoute: typeof MainNetworkLoadbalancerLocationUuidIndexLazyRoute
  MainNetworkVpcLocationUuidIndexLazyRoute: typeof MainNetworkVpcLocationUuidIndexLazyRoute
  MainServicesServiceLocationServiceIdIndexLazyRoute: typeof MainServicesServiceLocationServiceIdIndexLazyRoute
  MainBillingAccountIdDetailTypeInvoiceIdLazyRoute: typeof MainBillingAccountIdDetailTypeInvoiceIdLazyRoute
}

const MainRouteChildren: MainRouteChildren = {
  MainTestErrorLazyRoute: MainTestErrorLazyRoute,
  MainIndexLazyRoute: MainIndexLazyRoute,
  MainServicesCreateRoute: MainServicesCreateRoute,
  MainBillingCreateLazyRoute: MainBillingCreateLazyRoute,
  MainComputeCreateLazyRoute: MainComputeCreateLazyRoute,
  MainMetalCreateLazyRoute: MainMetalCreateLazyRoute,
  MainSectionNameLazyRoute: MainSectionNameLazyRoute,
  MainStorageCreateLazyRoute: MainStorageCreateLazyRoute,
  MainProfileIndexLazyRoute: MainProfileIndexLazyRoute,
  MainAccessCreateTypeLazyRoute: MainAccessCreateTypeLazyRoute,
  MainNetworkCreateTypeLazyRoute: MainNetworkCreateTypeLazyRoute,
  MainNetworkCreateIndexRoute: MainNetworkCreateIndexRoute,
  MainBillingAccountIdEditLazyRoute: MainBillingAccountIdEditLazyRoute,
  MainAccessDelegationIdIndexLazyRoute: MainAccessDelegationIdIndexLazyRoute,
  MainAccessTokenIdIndexLazyRoute: MainAccessTokenIdIndexLazyRoute,
  MainBillingAccountIdIndexLazyRoute: MainBillingAccountIdIndexLazyRoute,
  MainMetalLeaseUuidIndexLazyRoute: MainMetalLeaseUuidIndexLazyRoute,
  MainStorageBucketStorageNameIndexLazyRoute:
    MainStorageBucketStorageNameIndexLazyRoute,
  MainComputeVmLocationVmIdIndexLazyRoute:
    MainComputeVmLocationVmIdIndexLazyRoute,
  MainNetworkIpaddressLocationUuidIndexLazyRoute:
    MainNetworkIpaddressLocationUuidIndexLazyRoute,
  MainNetworkLoadbalancerLocationUuidIndexLazyRoute:
    MainNetworkLoadbalancerLocationUuidIndexLazyRoute,
  MainNetworkVpcLocationUuidIndexLazyRoute:
    MainNetworkVpcLocationUuidIndexLazyRoute,
  MainServicesServiceLocationServiceIdIndexLazyRoute:
    MainServicesServiceLocationServiceIdIndexLazyRoute,
  MainBillingAccountIdDetailTypeInvoiceIdLazyRoute:
    MainBillingAccountIdDetailTypeInvoiceIdLazyRoute,
}

const MainRouteWithChildren = MainRoute._addFileChildren(MainRouteChildren)

interface AdminSimpleRouteChildren {
  AdminSimpleAdminusersLazyRoute: typeof AdminSimpleAdminusersLazyRoute
  AdminSimpleCampaignsLazyRoute: typeof AdminSimpleCampaignsLazyRoute
  AdminSimpleConfigurationLazyRoute: typeof AdminSimpleConfigurationLazyRoute
  AdminSimpleCustomproductsLazyRoute: typeof AdminSimpleCustomproductsLazyRoute
  AdminSimpleGeneralLazyRoute: typeof AdminSimpleGeneralLazyRoute
  AdminSimpleHostpoolsLazyRoute: typeof AdminSimpleHostpoolsLazyRoute
  AdminSimpleLocationsLazyRoute: typeof AdminSimpleLocationsLazyRoute
  AdminSimplePricesLazyRoute: typeof AdminSimplePricesLazyRoute
  AdminSimpleReferralLazyRoute: typeof AdminSimpleReferralLazyRoute
  AdminSimpleTestComponentsLazyRoute: typeof AdminSimpleTestComponentsLazyRoute
  AdminSimpleTrackingLazyRoute: typeof AdminSimpleTrackingLazyRoute
  AdminSimpleVmconfLazyRoute: typeof AdminSimpleVmconfLazyRoute
  AdminSimpleVmimagesLazyRoute: typeof AdminSimpleVmimagesLazyRoute
  AdminSimpleIndexLazyRoute: typeof AdminSimpleIndexLazyRoute
  AdminSimpleMetalOsLazyRoute: typeof AdminSimpleMetalOsLazyRoute
  AdminSimpleMetalSpecsLazyRoute: typeof AdminSimpleMetalSpecsLazyRoute
  AdminSimpleMetalMachinesIndexLazyRoute: typeof AdminSimpleMetalMachinesIndexLazyRoute
  AdminSimpleMetalMachinesHistoryLocationUuidLazyRoute: typeof AdminSimpleMetalMachinesHistoryLocationUuidLazyRoute
}

const AdminSimpleRouteChildren: AdminSimpleRouteChildren = {
  AdminSimpleAdminusersLazyRoute: AdminSimpleAdminusersLazyRoute,
  AdminSimpleCampaignsLazyRoute: AdminSimpleCampaignsLazyRoute,
  AdminSimpleConfigurationLazyRoute: AdminSimpleConfigurationLazyRoute,
  AdminSimpleCustomproductsLazyRoute: AdminSimpleCustomproductsLazyRoute,
  AdminSimpleGeneralLazyRoute: AdminSimpleGeneralLazyRoute,
  AdminSimpleHostpoolsLazyRoute: AdminSimpleHostpoolsLazyRoute,
  AdminSimpleLocationsLazyRoute: AdminSimpleLocationsLazyRoute,
  AdminSimplePricesLazyRoute: AdminSimplePricesLazyRoute,
  AdminSimpleReferralLazyRoute: AdminSimpleReferralLazyRoute,
  AdminSimpleTestComponentsLazyRoute: AdminSimpleTestComponentsLazyRoute,
  AdminSimpleTrackingLazyRoute: AdminSimpleTrackingLazyRoute,
  AdminSimpleVmconfLazyRoute: AdminSimpleVmconfLazyRoute,
  AdminSimpleVmimagesLazyRoute: AdminSimpleVmimagesLazyRoute,
  AdminSimpleIndexLazyRoute: AdminSimpleIndexLazyRoute,
  AdminSimpleMetalOsLazyRoute: AdminSimpleMetalOsLazyRoute,
  AdminSimpleMetalSpecsLazyRoute: AdminSimpleMetalSpecsLazyRoute,
  AdminSimpleMetalMachinesIndexLazyRoute:
    AdminSimpleMetalMachinesIndexLazyRoute,
  AdminSimpleMetalMachinesHistoryLocationUuidLazyRoute:
    AdminSimpleMetalMachinesHistoryLocationUuidLazyRoute,
}

const AdminSimpleRouteWithChildren = AdminSimpleRoute._addFileChildren(
  AdminSimpleRouteChildren,
)

interface AdminRouteChildren {
  AdminSimpleRoute: typeof AdminSimpleRouteWithChildren
  AdminDualAccountsLazyRoute: typeof AdminDualAccountsLazyRoute
  AdminDualBucketsLazyRoute: typeof AdminDualBucketsLazyRoute
  AdminDualFloatingipsLazyRoute: typeof AdminDualFloatingipsLazyRoute
  AdminDualHostsLazyRoute: typeof AdminDualHostsLazyRoute
  AdminDualLoadbalancersLazyRoute: typeof AdminDualLoadbalancersLazyRoute
  AdminDualReportsLazyRoute: typeof AdminDualReportsLazyRoute
  AdminDualServicepackagesLazyRoute: typeof AdminDualServicepackagesLazyRoute
  AdminDualUsersLazyRoute: typeof AdminDualUsersLazyRoute
  AdminDualVirtualmachinesLazyRoute: typeof AdminDualVirtualmachinesLazyRoute
  AdminConsoleLocationVmIdLazyRoute: typeof AdminConsoleLocationVmIdLazyRoute
}

const AdminRouteChildren: AdminRouteChildren = {
  AdminSimpleRoute: AdminSimpleRouteWithChildren,
  AdminDualAccountsLazyRoute: AdminDualAccountsLazyRoute,
  AdminDualBucketsLazyRoute: AdminDualBucketsLazyRoute,
  AdminDualFloatingipsLazyRoute: AdminDualFloatingipsLazyRoute,
  AdminDualHostsLazyRoute: AdminDualHostsLazyRoute,
  AdminDualLoadbalancersLazyRoute: AdminDualLoadbalancersLazyRoute,
  AdminDualReportsLazyRoute: AdminDualReportsLazyRoute,
  AdminDualServicepackagesLazyRoute: AdminDualServicepackagesLazyRoute,
  AdminDualUsersLazyRoute: AdminDualUsersLazyRoute,
  AdminDualVirtualmachinesLazyRoute: AdminDualVirtualmachinesLazyRoute,
  AdminConsoleLocationVmIdLazyRoute: AdminConsoleLocationVmIdLazyRoute,
}

const AdminRouteWithChildren = AdminRoute._addFileChildren(AdminRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof MainRouteWithChildren
  '/admin': typeof AdminSimpleRouteWithChildren
  '/about': typeof AboutLazyRoute
  '/test-error': typeof MainTestErrorLazyRoute
  '/': typeof MainIndexLazyRoute
  '/services/create': typeof MainServicesCreateRoute
  '/billing/create': typeof MainBillingCreateLazyRoute
  '/compute/create': typeof MainComputeCreateLazyRoute
  '/metal/create': typeof MainMetalCreateLazyRoute
  '/section/$name': typeof MainSectionNameLazyRoute
  '/storage/create': typeof MainStorageCreateLazyRoute
  '/admin/accounts': typeof AdminDualAccountsLazyRoute
  '/admin/buckets': typeof AdminDualBucketsLazyRoute
  '/admin/floating_ips': typeof AdminDualFloatingipsLazyRoute
  '/admin/hosts': typeof AdminDualHostsLazyRoute
  '/admin/load_balancers': typeof AdminDualLoadbalancersLazyRoute
  '/admin/reports': typeof AdminDualReportsLazyRoute
  '/admin/service_packages': typeof AdminDualServicepackagesLazyRoute
  '/admin/users': typeof AdminDualUsersLazyRoute
  '/admin/virtual_machines': typeof AdminDualVirtualmachinesLazyRoute
  '/admin/admin_users': typeof AdminSimpleAdminusersLazyRoute
  '/admin/campaigns': typeof AdminSimpleCampaignsLazyRoute
  '/admin/configuration': typeof AdminSimpleConfigurationLazyRoute
  '/admin/custom_products': typeof AdminSimpleCustomproductsLazyRoute
  '/admin/general': typeof AdminSimpleGeneralLazyRoute
  '/admin/host_pools': typeof AdminSimpleHostpoolsLazyRoute
  '/admin/locations': typeof AdminSimpleLocationsLazyRoute
  '/admin/prices': typeof AdminSimplePricesLazyRoute
  '/admin/referral': typeof AdminSimpleReferralLazyRoute
  '/admin/test-components': typeof AdminSimpleTestComponentsLazyRoute
  '/admin/tracking': typeof AdminSimpleTrackingLazyRoute
  '/admin/vm_conf': typeof AdminSimpleVmconfLazyRoute
  '/admin/vm_images': typeof AdminSimpleVmimagesLazyRoute
  '/console/$location/$vmId': typeof ConsoleLocationVmIdLazyRoute
  '/profile': typeof MainProfileIndexLazyRoute
  '/admin/': typeof AdminSimpleIndexLazyRoute
  '/access/create/$type': typeof MainAccessCreateTypeLazyRoute
  '/network/create/$type': typeof MainNetworkCreateTypeLazyRoute
  '/admin/metal/os': typeof AdminSimpleMetalOsLazyRoute
  '/admin/metal/specs': typeof AdminSimpleMetalSpecsLazyRoute
  '/admin/console/$location/$vmId': typeof AdminConsoleLocationVmIdLazyRoute
  '/network/create': typeof MainNetworkCreateIndexRoute
  '/billing/account/$id/edit': typeof MainBillingAccountIdEditLazyRoute
  '/access/delegation/$id': typeof MainAccessDelegationIdIndexLazyRoute
  '/access/token/$id': typeof MainAccessTokenIdIndexLazyRoute
  '/billing/account/$id': typeof MainBillingAccountIdIndexLazyRoute
  '/metal/lease/$uuid': typeof MainMetalLeaseUuidIndexLazyRoute
  '/storage/bucket/$storageName': typeof MainStorageBucketStorageNameIndexLazyRoute
  '/admin/metal/machines': typeof AdminSimpleMetalMachinesIndexLazyRoute
  '/compute/vm/$location/$vmId': typeof MainComputeVmLocationVmIdIndexLazyRoute
  '/network/ip_address/$location/$uuid': typeof MainNetworkIpaddressLocationUuidIndexLazyRoute
  '/network/load_balancer/$location/$uuid': typeof MainNetworkLoadbalancerLocationUuidIndexLazyRoute
  '/network/vpc/$location/$uuid': typeof MainNetworkVpcLocationUuidIndexLazyRoute
  '/services/service/$location/$serviceId': typeof MainServicesServiceLocationServiceIdIndexLazyRoute
  '/billing/account/$id/detail/$type/$invoiceId': typeof MainBillingAccountIdDetailTypeInvoiceIdLazyRoute
  '/admin/metal/machines/history/$location/$uuid': typeof AdminSimpleMetalMachinesHistoryLocationUuidLazyRoute
}

export interface FileRoutesByTo {
  '/admin': typeof AdminSimpleIndexLazyRoute
  '/about': typeof AboutLazyRoute
  '/test-error': typeof MainTestErrorLazyRoute
  '/': typeof MainIndexLazyRoute
  '/services/create': typeof MainServicesCreateRoute
  '/billing/create': typeof MainBillingCreateLazyRoute
  '/compute/create': typeof MainComputeCreateLazyRoute
  '/metal/create': typeof MainMetalCreateLazyRoute
  '/section/$name': typeof MainSectionNameLazyRoute
  '/storage/create': typeof MainStorageCreateLazyRoute
  '/admin/accounts': typeof AdminDualAccountsLazyRoute
  '/admin/buckets': typeof AdminDualBucketsLazyRoute
  '/admin/floating_ips': typeof AdminDualFloatingipsLazyRoute
  '/admin/hosts': typeof AdminDualHostsLazyRoute
  '/admin/load_balancers': typeof AdminDualLoadbalancersLazyRoute
  '/admin/reports': typeof AdminDualReportsLazyRoute
  '/admin/service_packages': typeof AdminDualServicepackagesLazyRoute
  '/admin/users': typeof AdminDualUsersLazyRoute
  '/admin/virtual_machines': typeof AdminDualVirtualmachinesLazyRoute
  '/admin/admin_users': typeof AdminSimpleAdminusersLazyRoute
  '/admin/campaigns': typeof AdminSimpleCampaignsLazyRoute
  '/admin/configuration': typeof AdminSimpleConfigurationLazyRoute
  '/admin/custom_products': typeof AdminSimpleCustomproductsLazyRoute
  '/admin/general': typeof AdminSimpleGeneralLazyRoute
  '/admin/host_pools': typeof AdminSimpleHostpoolsLazyRoute
  '/admin/locations': typeof AdminSimpleLocationsLazyRoute
  '/admin/prices': typeof AdminSimplePricesLazyRoute
  '/admin/referral': typeof AdminSimpleReferralLazyRoute
  '/admin/test-components': typeof AdminSimpleTestComponentsLazyRoute
  '/admin/tracking': typeof AdminSimpleTrackingLazyRoute
  '/admin/vm_conf': typeof AdminSimpleVmconfLazyRoute
  '/admin/vm_images': typeof AdminSimpleVmimagesLazyRoute
  '/console/$location/$vmId': typeof ConsoleLocationVmIdLazyRoute
  '/profile': typeof MainProfileIndexLazyRoute
  '/access/create/$type': typeof MainAccessCreateTypeLazyRoute
  '/network/create/$type': typeof MainNetworkCreateTypeLazyRoute
  '/admin/metal/os': typeof AdminSimpleMetalOsLazyRoute
  '/admin/metal/specs': typeof AdminSimpleMetalSpecsLazyRoute
  '/admin/console/$location/$vmId': typeof AdminConsoleLocationVmIdLazyRoute
  '/network/create': typeof MainNetworkCreateIndexRoute
  '/billing/account/$id/edit': typeof MainBillingAccountIdEditLazyRoute
  '/access/delegation/$id': typeof MainAccessDelegationIdIndexLazyRoute
  '/access/token/$id': typeof MainAccessTokenIdIndexLazyRoute
  '/billing/account/$id': typeof MainBillingAccountIdIndexLazyRoute
  '/metal/lease/$uuid': typeof MainMetalLeaseUuidIndexLazyRoute
  '/storage/bucket/$storageName': typeof MainStorageBucketStorageNameIndexLazyRoute
  '/admin/metal/machines': typeof AdminSimpleMetalMachinesIndexLazyRoute
  '/compute/vm/$location/$vmId': typeof MainComputeVmLocationVmIdIndexLazyRoute
  '/network/ip_address/$location/$uuid': typeof MainNetworkIpaddressLocationUuidIndexLazyRoute
  '/network/load_balancer/$location/$uuid': typeof MainNetworkLoadbalancerLocationUuidIndexLazyRoute
  '/network/vpc/$location/$uuid': typeof MainNetworkVpcLocationUuidIndexLazyRoute
  '/services/service/$location/$serviceId': typeof MainServicesServiceLocationServiceIdIndexLazyRoute
  '/billing/account/$id/detail/$type/$invoiceId': typeof MainBillingAccountIdDetailTypeInvoiceIdLazyRoute
  '/admin/metal/machines/history/$location/$uuid': typeof AdminSimpleMetalMachinesHistoryLocationUuidLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_main': typeof MainRouteWithChildren
  '/admin': typeof AdminRouteWithChildren
  '/about': typeof AboutLazyRoute
  '/admin/_simple': typeof AdminSimpleRouteWithChildren
  '/_main/test-error': typeof MainTestErrorLazyRoute
  '/_main/': typeof MainIndexLazyRoute
  '/_main/services/create': typeof MainServicesCreateRoute
  '/_main/billing/create': typeof MainBillingCreateLazyRoute
  '/_main/compute/create': typeof MainComputeCreateLazyRoute
  '/_main/metal/create': typeof MainMetalCreateLazyRoute
  '/_main/section/$name': typeof MainSectionNameLazyRoute
  '/_main/storage/create': typeof MainStorageCreateLazyRoute
  '/admin/_dual/accounts': typeof AdminDualAccountsLazyRoute
  '/admin/_dual/buckets': typeof AdminDualBucketsLazyRoute
  '/admin/_dual/floating_ips': typeof AdminDualFloatingipsLazyRoute
  '/admin/_dual/hosts': typeof AdminDualHostsLazyRoute
  '/admin/_dual/load_balancers': typeof AdminDualLoadbalancersLazyRoute
  '/admin/_dual/reports': typeof AdminDualReportsLazyRoute
  '/admin/_dual/service_packages': typeof AdminDualServicepackagesLazyRoute
  '/admin/_dual/users': typeof AdminDualUsersLazyRoute
  '/admin/_dual/virtual_machines': typeof AdminDualVirtualmachinesLazyRoute
  '/admin/_simple/admin_users': typeof AdminSimpleAdminusersLazyRoute
  '/admin/_simple/campaigns': typeof AdminSimpleCampaignsLazyRoute
  '/admin/_simple/configuration': typeof AdminSimpleConfigurationLazyRoute
  '/admin/_simple/custom_products': typeof AdminSimpleCustomproductsLazyRoute
  '/admin/_simple/general': typeof AdminSimpleGeneralLazyRoute
  '/admin/_simple/host_pools': typeof AdminSimpleHostpoolsLazyRoute
  '/admin/_simple/locations': typeof AdminSimpleLocationsLazyRoute
  '/admin/_simple/prices': typeof AdminSimplePricesLazyRoute
  '/admin/_simple/referral': typeof AdminSimpleReferralLazyRoute
  '/admin/_simple/test-components': typeof AdminSimpleTestComponentsLazyRoute
  '/admin/_simple/tracking': typeof AdminSimpleTrackingLazyRoute
  '/admin/_simple/vm_conf': typeof AdminSimpleVmconfLazyRoute
  '/admin/_simple/vm_images': typeof AdminSimpleVmimagesLazyRoute
  '/console/$location/$vmId': typeof ConsoleLocationVmIdLazyRoute
  '/_main/profile/': typeof MainProfileIndexLazyRoute
  '/admin/_simple/': typeof AdminSimpleIndexLazyRoute
  '/_main/access/create/$type': typeof MainAccessCreateTypeLazyRoute
  '/_main/network/create/$type': typeof MainNetworkCreateTypeLazyRoute
  '/admin/_simple/metal/os': typeof AdminSimpleMetalOsLazyRoute
  '/admin/_simple/metal/specs': typeof AdminSimpleMetalSpecsLazyRoute
  '/admin/console/$location/$vmId': typeof AdminConsoleLocationVmIdLazyRoute
  '/_main/network/create/': typeof MainNetworkCreateIndexRoute
  '/_main/billing/account/$id/edit': typeof MainBillingAccountIdEditLazyRoute
  '/_main/access/delegation/$id/': typeof MainAccessDelegationIdIndexLazyRoute
  '/_main/access/token/$id/': typeof MainAccessTokenIdIndexLazyRoute
  '/_main/billing/account/$id/': typeof MainBillingAccountIdIndexLazyRoute
  '/_main/metal/lease/$uuid/': typeof MainMetalLeaseUuidIndexLazyRoute
  '/_main/storage/bucket/$storageName/': typeof MainStorageBucketStorageNameIndexLazyRoute
  '/admin/_simple/metal/machines/': typeof AdminSimpleMetalMachinesIndexLazyRoute
  '/_main/compute/vm/$location/$vmId/': typeof MainComputeVmLocationVmIdIndexLazyRoute
  '/_main/network/ip_address/$location/$uuid/': typeof MainNetworkIpaddressLocationUuidIndexLazyRoute
  '/_main/network/load_balancer/$location/$uuid/': typeof MainNetworkLoadbalancerLocationUuidIndexLazyRoute
  '/_main/network/vpc/$location/$uuid/': typeof MainNetworkVpcLocationUuidIndexLazyRoute
  '/_main/services/service/$location/$serviceId/': typeof MainServicesServiceLocationServiceIdIndexLazyRoute
  '/_main/billing/account/$id/detail/$type/$invoiceId': typeof MainBillingAccountIdDetailTypeInvoiceIdLazyRoute
  '/admin/_simple/metal/machines/history/$location/$uuid': typeof AdminSimpleMetalMachinesHistoryLocationUuidLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/admin'
    | '/about'
    | '/test-error'
    | '/'
    | '/services/create'
    | '/billing/create'
    | '/compute/create'
    | '/metal/create'
    | '/section/$name'
    | '/storage/create'
    | '/admin/accounts'
    | '/admin/buckets'
    | '/admin/floating_ips'
    | '/admin/hosts'
    | '/admin/load_balancers'
    | '/admin/reports'
    | '/admin/service_packages'
    | '/admin/users'
    | '/admin/virtual_machines'
    | '/admin/admin_users'
    | '/admin/campaigns'
    | '/admin/configuration'
    | '/admin/custom_products'
    | '/admin/general'
    | '/admin/host_pools'
    | '/admin/locations'
    | '/admin/prices'
    | '/admin/referral'
    | '/admin/test-components'
    | '/admin/tracking'
    | '/admin/vm_conf'
    | '/admin/vm_images'
    | '/console/$location/$vmId'
    | '/profile'
    | '/admin/'
    | '/access/create/$type'
    | '/network/create/$type'
    | '/admin/metal/os'
    | '/admin/metal/specs'
    | '/admin/console/$location/$vmId'
    | '/network/create'
    | '/billing/account/$id/edit'
    | '/access/delegation/$id'
    | '/access/token/$id'
    | '/billing/account/$id'
    | '/metal/lease/$uuid'
    | '/storage/bucket/$storageName'
    | '/admin/metal/machines'
    | '/compute/vm/$location/$vmId'
    | '/network/ip_address/$location/$uuid'
    | '/network/load_balancer/$location/$uuid'
    | '/network/vpc/$location/$uuid'
    | '/services/service/$location/$serviceId'
    | '/billing/account/$id/detail/$type/$invoiceId'
    | '/admin/metal/machines/history/$location/$uuid'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/admin'
    | '/about'
    | '/test-error'
    | '/'
    | '/services/create'
    | '/billing/create'
    | '/compute/create'
    | '/metal/create'
    | '/section/$name'
    | '/storage/create'
    | '/admin/accounts'
    | '/admin/buckets'
    | '/admin/floating_ips'
    | '/admin/hosts'
    | '/admin/load_balancers'
    | '/admin/reports'
    | '/admin/service_packages'
    | '/admin/users'
    | '/admin/virtual_machines'
    | '/admin/admin_users'
    | '/admin/campaigns'
    | '/admin/configuration'
    | '/admin/custom_products'
    | '/admin/general'
    | '/admin/host_pools'
    | '/admin/locations'
    | '/admin/prices'
    | '/admin/referral'
    | '/admin/test-components'
    | '/admin/tracking'
    | '/admin/vm_conf'
    | '/admin/vm_images'
    | '/console/$location/$vmId'
    | '/profile'
    | '/access/create/$type'
    | '/network/create/$type'
    | '/admin/metal/os'
    | '/admin/metal/specs'
    | '/admin/console/$location/$vmId'
    | '/network/create'
    | '/billing/account/$id/edit'
    | '/access/delegation/$id'
    | '/access/token/$id'
    | '/billing/account/$id'
    | '/metal/lease/$uuid'
    | '/storage/bucket/$storageName'
    | '/admin/metal/machines'
    | '/compute/vm/$location/$vmId'
    | '/network/ip_address/$location/$uuid'
    | '/network/load_balancer/$location/$uuid'
    | '/network/vpc/$location/$uuid'
    | '/services/service/$location/$serviceId'
    | '/billing/account/$id/detail/$type/$invoiceId'
    | '/admin/metal/machines/history/$location/$uuid'
  id:
    | '__root__'
    | '/_main'
    | '/admin'
    | '/about'
    | '/admin/_simple'
    | '/_main/test-error'
    | '/_main/'
    | '/_main/services/create'
    | '/_main/billing/create'
    | '/_main/compute/create'
    | '/_main/metal/create'
    | '/_main/section/$name'
    | '/_main/storage/create'
    | '/admin/_dual/accounts'
    | '/admin/_dual/buckets'
    | '/admin/_dual/floating_ips'
    | '/admin/_dual/hosts'
    | '/admin/_dual/load_balancers'
    | '/admin/_dual/reports'
    | '/admin/_dual/service_packages'
    | '/admin/_dual/users'
    | '/admin/_dual/virtual_machines'
    | '/admin/_simple/admin_users'
    | '/admin/_simple/campaigns'
    | '/admin/_simple/configuration'
    | '/admin/_simple/custom_products'
    | '/admin/_simple/general'
    | '/admin/_simple/host_pools'
    | '/admin/_simple/locations'
    | '/admin/_simple/prices'
    | '/admin/_simple/referral'
    | '/admin/_simple/test-components'
    | '/admin/_simple/tracking'
    | '/admin/_simple/vm_conf'
    | '/admin/_simple/vm_images'
    | '/console/$location/$vmId'
    | '/_main/profile/'
    | '/admin/_simple/'
    | '/_main/access/create/$type'
    | '/_main/network/create/$type'
    | '/admin/_simple/metal/os'
    | '/admin/_simple/metal/specs'
    | '/admin/console/$location/$vmId'
    | '/_main/network/create/'
    | '/_main/billing/account/$id/edit'
    | '/_main/access/delegation/$id/'
    | '/_main/access/token/$id/'
    | '/_main/billing/account/$id/'
    | '/_main/metal/lease/$uuid/'
    | '/_main/storage/bucket/$storageName/'
    | '/admin/_simple/metal/machines/'
    | '/_main/compute/vm/$location/$vmId/'
    | '/_main/network/ip_address/$location/$uuid/'
    | '/_main/network/load_balancer/$location/$uuid/'
    | '/_main/network/vpc/$location/$uuid/'
    | '/_main/services/service/$location/$serviceId/'
    | '/_main/billing/account/$id/detail/$type/$invoiceId'
    | '/admin/_simple/metal/machines/history/$location/$uuid'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  MainRoute: typeof MainRouteWithChildren
  AdminRoute: typeof AdminRouteWithChildren
  AboutLazyRoute: typeof AboutLazyRoute
  ConsoleLocationVmIdLazyRoute: typeof ConsoleLocationVmIdLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  MainRoute: MainRouteWithChildren,
  AdminRoute: AdminRouteWithChildren,
  AboutLazyRoute: AboutLazyRoute,
  ConsoleLocationVmIdLazyRoute: ConsoleLocationVmIdLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_main",
        "/admin",
        "/about",
        "/console/$location/$vmId"
      ]
    },
    "/_main": {
      "filePath": "_main.tsx",
      "children": [
        "/_main/test-error",
        "/_main/",
        "/_main/services/create",
        "/_main/billing/create",
        "/_main/compute/create",
        "/_main/metal/create",
        "/_main/section/$name",
        "/_main/storage/create",
        "/_main/profile/",
        "/_main/access/create/$type",
        "/_main/network/create/$type",
        "/_main/network/create/",
        "/_main/billing/account/$id/edit",
        "/_main/access/delegation/$id/",
        "/_main/access/token/$id/",
        "/_main/billing/account/$id/",
        "/_main/metal/lease/$uuid/",
        "/_main/storage/bucket/$storageName/",
        "/_main/compute/vm/$location/$vmId/",
        "/_main/network/ip_address/$location/$uuid/",
        "/_main/network/load_balancer/$location/$uuid/",
        "/_main/network/vpc/$location/$uuid/",
        "/_main/services/service/$location/$serviceId/",
        "/_main/billing/account/$id/detail/$type/$invoiceId"
      ]
    },
    "/admin": {
      "filePath": "admin.tsx",
      "children": [
        "/admin/_simple",
        "/admin/_dual/accounts",
        "/admin/_dual/buckets",
        "/admin/_dual/floating_ips",
        "/admin/_dual/hosts",
        "/admin/_dual/load_balancers",
        "/admin/_dual/reports",
        "/admin/_dual/service_packages",
        "/admin/_dual/users",
        "/admin/_dual/virtual_machines",
        "/admin/console/$location/$vmId"
      ]
    },
    "/about": {
      "filePath": "about.lazy.tsx"
    },
    "/admin/_simple": {
      "filePath": "admin/_simple.tsx",
      "parent": "/admin",
      "children": [
        "/admin/_simple/admin_users",
        "/admin/_simple/campaigns",
        "/admin/_simple/configuration",
        "/admin/_simple/custom_products",
        "/admin/_simple/general",
        "/admin/_simple/host_pools",
        "/admin/_simple/locations",
        "/admin/_simple/prices",
        "/admin/_simple/referral",
        "/admin/_simple/test-components",
        "/admin/_simple/tracking",
        "/admin/_simple/vm_conf",
        "/admin/_simple/vm_images",
        "/admin/_simple/",
        "/admin/_simple/metal/os",
        "/admin/_simple/metal/specs",
        "/admin/_simple/metal/machines/",
        "/admin/_simple/metal/machines/history/$location/$uuid"
      ]
    },
    "/_main/test-error": {
      "filePath": "_main/test-error.lazy.tsx",
      "parent": "/_main"
    },
    "/_main/": {
      "filePath": "_main/index.lazy.tsx",
      "parent": "/_main"
    },
    "/_main/services/create": {
      "filePath": "_main/services/create.tsx",
      "parent": "/_main"
    },
    "/_main/billing/create": {
      "filePath": "_main/billing/create.lazy.tsx",
      "parent": "/_main"
    },
    "/_main/compute/create": {
      "filePath": "_main/compute/create.lazy.tsx",
      "parent": "/_main"
    },
    "/_main/metal/create": {
      "filePath": "_main/metal/create.lazy.tsx",
      "parent": "/_main"
    },
    "/_main/section/$name": {
      "filePath": "_main/section/$name.lazy.tsx",
      "parent": "/_main"
    },
    "/_main/storage/create": {
      "filePath": "_main/storage/create.lazy.tsx",
      "parent": "/_main"
    },
    "/admin/_dual/accounts": {
      "filePath": "admin/_dual/accounts.lazy.tsx",
      "parent": "/admin"
    },
    "/admin/_dual/buckets": {
      "filePath": "admin/_dual/buckets.lazy.tsx",
      "parent": "/admin"
    },
    "/admin/_dual/floating_ips": {
      "filePath": "admin/_dual/floating_ips.lazy.tsx",
      "parent": "/admin"
    },
    "/admin/_dual/hosts": {
      "filePath": "admin/_dual/hosts.lazy.tsx",
      "parent": "/admin"
    },
    "/admin/_dual/load_balancers": {
      "filePath": "admin/_dual/load_balancers.lazy.tsx",
      "parent": "/admin"
    },
    "/admin/_dual/reports": {
      "filePath": "admin/_dual/reports.lazy.tsx",
      "parent": "/admin"
    },
    "/admin/_dual/service_packages": {
      "filePath": "admin/_dual/service_packages.lazy.tsx",
      "parent": "/admin"
    },
    "/admin/_dual/users": {
      "filePath": "admin/_dual/users.lazy.tsx",
      "parent": "/admin"
    },
    "/admin/_dual/virtual_machines": {
      "filePath": "admin/_dual/virtual_machines.lazy.tsx",
      "parent": "/admin"
    },
    "/admin/_simple/admin_users": {
      "filePath": "admin/_simple/admin_users.lazy.tsx",
      "parent": "/admin/_simple"
    },
    "/admin/_simple/campaigns": {
      "filePath": "admin/_simple/campaigns.lazy.tsx",
      "parent": "/admin/_simple"
    },
    "/admin/_simple/configuration": {
      "filePath": "admin/_simple/configuration.lazy.tsx",
      "parent": "/admin/_simple"
    },
    "/admin/_simple/custom_products": {
      "filePath": "admin/_simple/custom_products.lazy.tsx",
      "parent": "/admin/_simple"
    },
    "/admin/_simple/general": {
      "filePath": "admin/_simple/general.lazy.tsx",
      "parent": "/admin/_simple"
    },
    "/admin/_simple/host_pools": {
      "filePath": "admin/_simple/host_pools.lazy.tsx",
      "parent": "/admin/_simple"
    },
    "/admin/_simple/locations": {
      "filePath": "admin/_simple/locations.lazy.tsx",
      "parent": "/admin/_simple"
    },
    "/admin/_simple/prices": {
      "filePath": "admin/_simple/prices.lazy.tsx",
      "parent": "/admin/_simple"
    },
    "/admin/_simple/referral": {
      "filePath": "admin/_simple/referral.lazy.tsx",
      "parent": "/admin/_simple"
    },
    "/admin/_simple/test-components": {
      "filePath": "admin/_simple/test-components.lazy.tsx",
      "parent": "/admin/_simple"
    },
    "/admin/_simple/tracking": {
      "filePath": "admin/_simple/tracking.lazy.tsx",
      "parent": "/admin/_simple"
    },
    "/admin/_simple/vm_conf": {
      "filePath": "admin/_simple/vm_conf.lazy.tsx",
      "parent": "/admin/_simple"
    },
    "/admin/_simple/vm_images": {
      "filePath": "admin/_simple/vm_images.lazy.tsx",
      "parent": "/admin/_simple"
    },
    "/console/$location/$vmId": {
      "filePath": "console.$location.$vmId.lazy.tsx"
    },
    "/_main/profile/": {
      "filePath": "_main/profile/index.lazy.tsx",
      "parent": "/_main"
    },
    "/admin/_simple/": {
      "filePath": "admin/_simple/index.lazy.tsx",
      "parent": "/admin/_simple"
    },
    "/_main/access/create/$type": {
      "filePath": "_main/access/create.$type.lazy.tsx",
      "parent": "/_main"
    },
    "/_main/network/create/$type": {
      "filePath": "_main/network/create.$type.lazy.tsx",
      "parent": "/_main"
    },
    "/admin/_simple/metal/os": {
      "filePath": "admin/_simple/metal/os.lazy.tsx",
      "parent": "/admin/_simple"
    },
    "/admin/_simple/metal/specs": {
      "filePath": "admin/_simple/metal/specs.lazy.tsx",
      "parent": "/admin/_simple"
    },
    "/admin/console/$location/$vmId": {
      "filePath": "admin/console.$location.$vmId.lazy.tsx",
      "parent": "/admin"
    },
    "/_main/network/create/": {
      "filePath": "_main/network/create.index.tsx",
      "parent": "/_main"
    },
    "/_main/billing/account/$id/edit": {
      "filePath": "_main/billing/account.$id/edit.lazy.tsx",
      "parent": "/_main"
    },
    "/_main/access/delegation/$id/": {
      "filePath": "_main/access/delegation/$id/index.lazy.tsx",
      "parent": "/_main"
    },
    "/_main/access/token/$id/": {
      "filePath": "_main/access/token/$id/index.lazy.tsx",
      "parent": "/_main"
    },
    "/_main/billing/account/$id/": {
      "filePath": "_main/billing/account.$id/index.lazy.tsx",
      "parent": "/_main"
    },
    "/_main/metal/lease/$uuid/": {
      "filePath": "_main/metal/lease.$uuid/index.lazy.tsx",
      "parent": "/_main"
    },
    "/_main/storage/bucket/$storageName/": {
      "filePath": "_main/storage/bucket.$storageName/index.lazy.tsx",
      "parent": "/_main"
    },
    "/admin/_simple/metal/machines/": {
      "filePath": "admin/_simple/metal/machines/index.lazy.tsx",
      "parent": "/admin/_simple"
    },
    "/_main/compute/vm/$location/$vmId/": {
      "filePath": "_main/compute/vm.$location.$vmId/index.lazy.tsx",
      "parent": "/_main"
    },
    "/_main/network/ip_address/$location/$uuid/": {
      "filePath": "_main/network/ip_address/$location/$uuid/index.lazy.tsx",
      "parent": "/_main"
    },
    "/_main/network/load_balancer/$location/$uuid/": {
      "filePath": "_main/network/load_balancer/$location/$uuid/index.lazy.tsx",
      "parent": "/_main"
    },
    "/_main/network/vpc/$location/$uuid/": {
      "filePath": "_main/network/vpc/$location/$uuid/index.lazy.tsx",
      "parent": "/_main"
    },
    "/_main/services/service/$location/$serviceId/": {
      "filePath": "_main/services/service.$location.$serviceId/index.lazy.tsx",
      "parent": "/_main"
    },
    "/_main/billing/account/$id/detail/$type/$invoiceId": {
      "filePath": "_main/billing/account.$id/detail.$type.$invoiceId.lazy.tsx",
      "parent": "/_main"
    },
    "/admin/_simple/metal/machines/history/$location/$uuid": {
      "filePath": "admin/_simple/metal/machines/history.$location.$uuid.lazy.tsx",
      "parent": "/admin/_simple"
    }
  }
}
ROUTE_MANIFEST_END */
